export const extensionRoutes = [  {
    path: 'extensions/offers-app',
    loadChildren: () => import('./extensions/offer-extension/routes'),
  },
  {
    path: 'extensions/product-reviews-app',
    loadChildren: () => import('./extensions/product-review-extension/routes'),
  },
  {
    path: 'extensions/banners-app',
    loadChildren: () => import('./extensions/banner-extension/routes'),
  }];
