import {Component} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {DataService} from "@vendure/admin-ui/core";

import {
  CouponInfo,
  getCustomerFragment,
  getOrderFragment,
  getOrderSurcharges,
  OrderDetailFragmentWithCustomFields,
  orderHasValidPayments,
  OrderSurcharge,
  safelyParseJSON,
} from "./invoice-helpers";

@Component({
  selector: "invoice-html",
  templateUrl: "./invoice.html",
  styleUrls: ["./invoice-page.scss", "./invoice.scss"],
})
export class InvoiceComponent {
  order: OrderDetailFragmentWithCustomFields;
  customerNumber: number;
  coupons: CouponInfo[];
  surcharges?: OrderSurcharge[];

  // noinspection JSUnusedGlobalSymbols
  abs = Math.abs;

  private async retrieveOrderData(orderId: string) {
    const goAway = () => this.router.navigate(["/"]);

    const order = await getOrderFragment(orderId, this.dataService);

    if (!order || !orderHasValidPayments(order) || !order.customer) {
      return goAway();
    }

    const customer = await getCustomerFragment(order.customer.id, this.dataService);

    this.customerNumber = (customer.customFields as any).customerNumber;
    this.coupons = safelyParseJSON(order.customFields.coupons, []);
    this.order = order;

    this.surcharges = getOrderSurcharges(order);
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
  ) {
    const orderId = route.snapshot.params["orderId"];

    this.retrieveOrderData(orderId).then();
  }
}
