import {addActionBarItem, addNavMenuItem} from "@vendure/admin-ui/core";
import _ from "lodash";

export default [
  addNavMenuItem(
    {
      id: "product-review",
      label: "Bewertungen",
      routerLink: ["/extensions/product-reviews-app"],
      icon: "atom", // https://clarity.design/documentation/icons/shapes,
      requiresPermission: "ReadCatalog",
    },
    "extensions",
  ),
  addActionBarItem(
    {
      id: "product-review",
      label: "Bewertungen",
      locationId: "product-list",
      onClick: _.noop,
      routerLink: ["/extensions/product-reviews-app"],
      buttonColor: "primary", // 'success' | 'warning'
      buttonStyle: "outline", // 'solid' | 'link'
      icon: "application",
      requiresPermission: "ReadCatalog",
    },
  ),
];
