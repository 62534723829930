import _ from "lodash";
import {
  CustomerFragment,
  DataService,
  GetCustomerListQuery,
  GetOrderQuery,
  GetOrderQueryVariables,
  OrderDetailFragment,
} from "@vendure/admin-ui/core";
import {ID, Order, Payment, Refund} from "@vendure/core";
import {CustomCustomerFields, CustomOrderFields} from "@vendure/core/dist/entity/custom-entity-fields";
import gql from "graphql-tag";

import {queryResultToPromise} from "../shared-extension";

export enum InvoiceType {
  INVOICE = "INV",
  DELIVERY_NOTE = "DN",
  REFUND = "RF"
}

export type CouponInfo = {
  code: string,
  amountWithTax: number,
}

type RefundEntry = {
  variantName: string,
  variantSku: string,
  unitPriceWithTax: number,
  refundPriceWithTax: number,
  proratedRefundPriceWithTax: number,
  refundQuantity: number,
  proratedRefundTax: number,
}

export type OrderSurcharge = {
  reason?: string,
  amount: number,
  amountWithTax: number,
  tax: number,
}

export type RefundInfo = {
  totalRefundPriceWithTax: number,
  totalTaxFromLines: number,
  entries: RefundEntry[],
  coupon?: CouponInfo,
  adjustment?: number,
  refundNumber: string,
  createdAt: string,
}

type TD_RefundLine = {
  orderLineId: ID,
  quantity: number,
}

export type CustomerFragmentWithCustomFields = CustomerFragment & {
  customFields: CustomCustomerFields
};

export type OrderDetailFragmentWithCustomFields = OrderDetailFragment & {
  customFields: CustomOrderFields & {
    coupons: string,
  }
};

type GetCustomerQuery = { customer: GetCustomerListQuery["customers"]["items"][number] };

type OrderDetailFragmentRefund = (Exclude<OrderDetailFragment["payments"], null | undefined>)[number]["refunds"][number];

type OrderDetailFragmentLine = OrderDetailFragment["lines"][number];

export async function getCustomerFragment(customerId: ID, dataService: DataService): Promise<CustomerFragmentWithCustomFields> {
  // Importing ES module from CommonJS module
  const {CUSTOMER_FRAGMENT} = await import("@vendure/admin-ui/core");

  // noinspection GraphQLUnresolvedReference
  const queryResult = dataService.query<GetCustomerQuery>(gql`
    query GetCustomer($id: ID!) {
      customer(id: $id) {
        ...Customer
      }
    }
    ${CUSTOMER_FRAGMENT}
  `, {id: customerId});

  const result = await queryResultToPromise(queryResult);

  return result.customer as CustomerFragmentWithCustomFields;
}

export async function getOrderFragment(orderId: string, dataService: DataService): Promise<OrderDetailFragmentWithCustomFields> {
  // Importing ES module from CommonJS module
  const {GET_ORDER} = await import("@vendure/admin-ui/core");

  const queryResult = dataService.query<GetOrderQuery, GetOrderQueryVariables>(GET_ORDER, {id: orderId});

  const result = await queryResultToPromise(queryResult);

  return result.order as OrderDetailFragmentWithCustomFields;
}

type OrderOrFragment = OrderDetailFragmentWithCustomFields | Order;

const getRefundEntries = (order: OrderDetailFragmentWithCustomFields, refundLines: TD_RefundLine[]): RefundEntry[] => {
  const extendedLines = refundLines.map(({orderLineId, quantity}) => {
    const orderLine = _.find(order.lines, {id: orderLineId}) as OrderDetailFragmentLine;

    return {
      ...orderLine,
      refundQuantity: quantity,
    };
  });

  return _
    .chain(extendedLines)
    .filter("refundQuantity")
    .groupBy("productVariant.sku")
    .mapValues(lines => {
      const sampleLine = lines[0];

      return {
        variantName: sampleLine.productVariant.name,
        variantSku: sampleLine.productVariant.sku,
        unitPriceWithTax: sampleLine.unitPriceWithTax,
        refundQuantity: _.sumBy(lines, "refundQuantity"),
        refundPriceWithTax: _.sumBy(lines, line => line.unitPriceWithTax * line.refundQuantity),
        proratedRefundPriceWithTax: _.sumBy(lines, line => line.proratedUnitPriceWithTax * line.refundQuantity),
        proratedRefundTax: _.sumBy(lines, line => (line.proratedUnitPriceWithTax - line.proratedUnitPrice) * line.refundQuantity),
      };
    })
    .values()
    .value();
};

export function getOrderSurcharges(order: OrderDetailFragmentWithCustomFields): OrderSurcharge[] {
  const surchargeIds = _.flatMap(order.modifications, ({surcharges}) => _.map(surcharges, "id"));
  const surcharges = _.filter(order.surcharges, ({id}) => surchargeIds.includes(id));

  return _.transform(surcharges, (result, {description, price, priceWithTax}) => {
    const tax = priceWithTax - price;

    if (priceWithTax != 0) {
      result.push({
        reason: description,
        amount: price,
        amountWithTax: priceWithTax,
        tax: tax,
      });
    }
  }, [] as OrderSurcharge[]);
}

export async function getRefundsInfo(order: OrderDetailFragmentWithCustomFields): Promise<RefundInfo[]> {
  const refunds = _
    .chain(order.payments)
    .flatMap(payment => payment.refunds)
    .filter(refund => isRefundValid(refund, order))
    .sortBy("metadata.refundNumber")
    .value();

  return refunds.map(refund => {
    const {createdAt, lines, metadata: {refundNumber}, total: totalRefundPriceWithTax} = refund;

    const entries = getRefundEntries(order, lines);

    const totalRefundPriceWithTaxFromLines = _.sumBy(entries, "refundPriceWithTax");
    const totalProratedRefundPriceWithTaxFromLines = _.sumBy(entries, "proratedRefundPriceWithTax");
    const totalTaxFromLines = _.sumBy(entries, "proratedRefundTax");

    const adjustment = totalProratedRefundPriceWithTaxFromLines - totalRefundPriceWithTax;

    const coupons = safelyParseJSON(order.customFields.coupons, []);
    const couponAmountWithTax = totalProratedRefundPriceWithTaxFromLines - totalRefundPriceWithTaxFromLines;

    const coupon = (_.isEmpty(coupons) || couponAmountWithTax == 0) ? undefined : {
      code: coupons.map(({code}) => code).join(" | "),
      amountWithTax: couponAmountWithTax,
    };

    return {
      totalRefundPriceWithTax,
      totalTaxFromLines,
      entries,
      coupon,
      adjustment,
      refundNumber,
      createdAt,
    };
  });
}

export function getInvoiceFileName(order: OrderOrFragment, type: InvoiceType) {
  const numRefunds = type === InvoiceType.REFUND
    ? _.sumBy((order as Order).payments, ({refunds}) => refunds.filter(refund => refund.state === "Settled").length)
    : 0;

  const code = _.replace(order.code, /\//g, "");
  const fullName = _.compact([code, type, numRefunds]).join("_");

  return `${fullName}.pdf`;
}

function isRefundValid(refund: Refund | OrderDetailFragmentRefund, order: OrderOrFragment) {
  if (refund.state !== "Settled") {
    return false;
  }

  // We filter out refunds that are coming from order modifications as those are not proper refunds.
  return !_.find(order.modifications, {refund: {id: refund.id}});
}

export function orderHasValidRefunds(order: OrderOrFragment, {warnFn = console.warn} = {}) {
  const numRefunds = (order as Order).payments
    .flatMap(payment => payment.refunds)
    .filter(refund => isRefundValid(refund, order))
    .length;

  if (!numRefunds) {
    warnFn(`order has no valid refunds`);
  }

  return !!numRefunds;
}

export function orderHasValidPayments(order: OrderOrFragment, {warnFn = console.warn} = {}) {
  const payments = (order.payments as Payment[] || []).filter(payment => payment.state === "Settled");

  if (_.isEmpty(payments)) {
    warnFn(`order has no valid payments`);

    return false;
  }

  return true;
}

export const generateInvoiceNumber = (lastInvoiceNumber?: string | null, prefix = "") => {
  const COUNTER_DIGITS = 5;

  const currentYear = new Date().getFullYear();
  const parts = lastInvoiceNumber && lastInvoiceNumber.substring(prefix.length).split("/");
  const counter = parts ? parseInt(parts[0]) : 0;
  const year = parts ? parseInt(parts[1]) : currentYear;

  const generate = (counter: number) => [
    prefix,
    _.padStart(counter.toString(), COUNTER_DIGITS, "0"),
    "/",
    currentYear,
  ].join("");

  if (year < currentYear) {
    return generate(1);
  }

  return generate(counter + 1);
};

export const safelyParseJSON = (json: string, defaultValue?: any) => {
  try {
    return JSON.parse(json) || defaultValue;
  } catch (error) {
    return defaultValue;
  }
};
