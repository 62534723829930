import {addNavMenuSection} from "@vendure/admin-ui/core";

export default [
  addNavMenuSection(
    {
      id: "extensions",
      label: "Extensions",
      items: [],

      // This should be adjusted as more items are added to this section in the future.
      requiresPermission: "ReadCatalog",
    },

    // Add this section before the "settings" section
    "settings",
  ),
];
