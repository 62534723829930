import {ActionBarContext, addActionBarItem, NotificationService} from "@vendure/admin-ui/core";
import _ from "lodash";
import {map, switchMap} from "rxjs/operators";

import {
  getInvoiceFileName,
  getOrderFragment,
  InvoiceType,
  OrderDetailFragmentWithCustomFields,
  orderHasValidPayments,
  orderHasValidRefunds,
} from "./invoice-helpers";

const checkInvoiceAvailability = (order: OrderDetailFragmentWithCustomFields, notificationService?: NotificationService) => {
  if (!orderHasValidPayments(order)) {
    notificationService?.warning("order.no-valid-payment-error");

    return false;
  }

  return true;
};

const checkRefundInvoiceAvailability = (order: OrderDetailFragmentWithCustomFields, notificationService?: NotificationService) => {
  if (!orderHasValidRefunds(order)) {
    notificationService?.warning("order.no-valid-refund-error");

    return false;
  }

  return true;
};

const checkDeliveryNoteAvailability = (order: OrderDetailFragmentWithCustomFields, notificationService?: NotificationService) => {
  const totalQuantity = _.sumBy(order.lines, "quantity");

  if (totalQuantity < 1) {
    notificationService?.warning("order.no-active-lines-error");

    return false;
  }

  if (!orderHasValidPayments(order)) {
    notificationService?.warning("order.no-valid-payment-error");

    return false;
  }

  return true;
};


export default [
  addActionBarItem({
    id: "print-invoice-action",
    buttonColor: "primary", // 'success' | 'warning',
    buttonStyle: "link", // 'solid' | 'outline'
    icon: "euro",
    label: "Rechnung",
    locationId: "order-detail",
    requiresPermission: "ReadOrder",
    buttonState: context => {
      return context.route.data.pipe(
        switchMap(data => data.detail.entity),
        map((order: any) => {
          const isInvoiceAvailable = checkInvoiceAvailability(order);

          return {
            disabled: !isInvoiceAvailable,
            visible: true,
          };
        }),
      );
    },
    onClick: (_event: MouseEvent, context: ActionBarContext) => {
      const {route, dataService, notificationService} = context;
      const orderId = route.snapshot.params["id"];

      getOrderFragment(orderId, dataService).then(order => {
        const isInvoiceAvailable = checkInvoiceAvailability(order, notificationService);

        if (isInvoiceAvailable) {
          const fileName = getInvoiceFileName(order, InvoiceType.INVOICE);

          window.open(`/orders/${orderId}/invoice/pdf/${fileName}`);
        }
      });
    },
  }),
  addActionBarItem({
    id: "print-refund-invoice-action",
    buttonColor: "primary", // 'success' | 'warning',
    buttonStyle: "link", // 'solid' | 'outline'
    icon: "undo",
    label: "Gutschriftsbeleg",
    locationId: "order-detail",
    requiresPermission: "ReadOrder",
    buttonState: context => {
      return context.route.data.pipe(
        switchMap(data => data.detail.entity),
        map((order: any) => {
          const isRefundInvoiceAvailable = checkRefundInvoiceAvailability(order);

          return {
            disabled: !isRefundInvoiceAvailable,
            visible: isRefundInvoiceAvailable,
          };
        }),
      );
    },
    onClick: (_event: MouseEvent, context: ActionBarContext) => {
      const {route, dataService, notificationService} = context;
      const orderId = route.snapshot.params["id"];

      getOrderFragment(orderId, dataService).then(order => {
        const isRefundInvoiceAvailable = checkRefundInvoiceAvailability(order, notificationService);

        if (isRefundInvoiceAvailable) {
          const fileName = getInvoiceFileName(order, InvoiceType.REFUND);

          window.open(`/orders/${orderId}/refund-invoice/pdf/${fileName}`);
        }
      });
    },
  }),
  addActionBarItem({
    id: "print-delivery-note-action",
    buttonColor: "primary", // 'success' | 'warning',
    buttonStyle: "link", // 'solid' | 'outline'
    icon: "truck",
    label: "Lieferschein",
    locationId: "order-detail",
    requiresPermission: "ReadOrder",
    buttonState: context => {
      return context.route.data.pipe(
        switchMap(data => data.detail.entity),
        map((order: any) => {
          const isDeliveryNoteAvailable = checkDeliveryNoteAvailability(order);

          return {
            disabled: !isDeliveryNoteAvailable,
            visible: true,
          };
        }),
      );
    },
    onClick: (_event: MouseEvent, context: ActionBarContext) => {
      const {route, dataService, notificationService} = context;
      const orderId = route.snapshot.params["id"];

      getOrderFragment(orderId, dataService).then(order => {
        const isDeliveryNoteAvailable = checkDeliveryNoteAvailability(order, notificationService);

        if (isDeliveryNoteAvailable) {
          const fileName = getInvoiceFileName(order, InvoiceType.DELIVERY_NOTE);

          window.open(`/orders/${orderId}/delivery-note/pdf/${fileName}`);
        }
      });
    },
  }),
];
