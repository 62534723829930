import _ from "lodash";
import {Component} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {DataService} from "@vendure/admin-ui/core";

import {
  getCustomerFragment,
  getOrderFragment,
  OrderDetailFragmentWithCustomFields,
  orderHasValidPayments,
} from "./invoice-helpers";

@Component({
  selector: "delivery-note-html",
  templateUrl: "./delivery-note.html",
  styleUrls: ["./invoice-page.scss", "./delivery-note.scss"],
})

export class DeliveryNoteComponent {
  customerNumber: number;
  date: Date;
  order: OrderDetailFragmentWithCustomFields;

  types = ["Lieferschein intern", "Lieferschein"];

  private async retrieveOrderData(orderId: string) {
    const goAway = () => this.router.navigate(["/"]);

    const order = await getOrderFragment(orderId, this.dataService);
    const totalQuantity = order && _.sumBy(order.lines, "quantity");

    if (!order || !totalQuantity || !orderHasValidPayments(order) || !order.customer) {
      return goAway();
    }

    const customer = await getCustomerFragment(order.customer.id, this.dataService);

    this.customerNumber = (customer.customFields as any).customerNumber;
    this.order = order;
    this.date = new Date();
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
  ) {
    const orderId = route.snapshot.params["orderId"];

    this.retrieveOrderData(orderId).then();
  }
}
