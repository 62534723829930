import compact from "lodash/compact";
import concat from "lodash/concat";
import flatMap from "lodash/flatMap";
import isPlainObject from "lodash/isPlainObject";
import pickBy from "lodash/pickBy";

type ClassNameObject = {
  [key: string]: any;
};

type ClassName = string | ClassNameObject | null | undefined;

const isClassNameObject = (className: ClassName): className is ClassNameObject => isPlainObject(className);

function buildClassList(classNames: ClassName | ClassName[]): string[] {
  const cleanClassNames = compact(concat(classNames));

  return flatMap(cleanClassNames, className => {
    if (isClassNameObject(className)) {
      return Object.keys(pickBy(className));
    }

    return className;
  });
}

export const buildClassName = (
  componentName?: string | null,
  modifiers: ClassName | ClassName[] = [],
  classNames: ClassName | ClassName[] = [],
): string => {
  const modifiersList = componentName ? buildClassList(modifiers).map(name => `${componentName}--${name}`) : [];
  const classNameList = buildClassList(classNames);

  return compact([componentName, ...modifiersList, ...classNameList]).join(" ");
};
