import _ from "lodash";
import gql from "graphql-tag";
import React, {useEffect, useState} from "react";
import {DataService} from "@vendure/admin-ui/core";
import {Card, FormField, useDetailComponentData, useInjector} from "@vendure/admin-ui/react";
import {ID} from "@vendure/core";

import {Codegen, queryResultToPromise} from "../";

const GET_PRODUCT_VARIANT = gql`
  query GetProductVariant($id: ID!) {
    productVariant(id: $id) {
      id
      stockLevels {
        stockOnHand
        stockAllocated
      }
      soldUnits
    }
  }
`;

const getProductVariant = async (id: ID, dataService: DataService): Promise<Codegen.ProductVariant> => {
  const queryResult = dataService.query<{ productVariant: Codegen.ProductVariant }, { id: ID }>(GET_PRODUCT_VARIANT, {id});

  const result = await queryResultToPromise(queryResult);

  return result.productVariant;
};

const getStockOnHand = (productVariant: Codegen.ProductVariant) => {
  return _.sumBy(productVariant.stockLevels, "stockOnHand");
};

const getSaleableAmount = (productVariant: Codegen.ProductVariant) => {
  return _.sumBy(productVariant.stockLevels, stockLevel =>
    Math.max(0, stockLevel.stockOnHand - stockLevel.stockAllocated),
  );
};

const getStockAllocated = (productVariant: Codegen.ProductVariant) => {
  return _.sumBy(productVariant.stockLevels, "stockAllocated");
};

export const ProductVariantInfo = () => {
  const dataService = useInjector(DataService);
  const {entity} = useDetailComponentData();
  const [productVariant, setProductVariant] = useState<Codegen.ProductVariant | null>(null);

  useEffect(() => {
    if (entity) {
      getProductVariant(entity.id, dataService).then(setProductVariant);
    }
  }, [entity]);

  return (
    <Card title="Zusätzliche Informationen">
      <div className="form-grid">
        <FormField label="Lagerbestand">
          {productVariant && getStockOnHand(productVariant)}
        </FormField>

        <FormField label="Verkauft">
          {productVariant && productVariant.soldUnits}
        </FormField>

        <FormField label="Reserviert">
          {productVariant && getStockAllocated(productVariant)}
        </FormField>

        <FormField label="Verkäuflich">
          {productVariant && getSaleableAmount(productVariant)}
        </FormField>
      </div>
    </Card>
  );
};
