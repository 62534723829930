import _ from "lodash";
import {Component} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {DataService} from "@vendure/admin-ui/core";

import {
  getCustomerFragment,
  getOrderFragment,
  getRefundsInfo,
  OrderDetailFragmentWithCustomFields,
  orderHasValidRefunds,
  RefundInfo,
} from "./invoice-helpers";

@Component({
  selector: "refund-invoice-html",
  templateUrl: "./refund-invoice.html",
  styleUrls: ["./invoice-page.scss", "./invoice.scss"],
})

export class RefundInvoiceComponent {
  order: OrderDetailFragmentWithCustomFields;
  customerNumber: number;
  refundsInfo: RefundInfo[];

  // noinspection JSUnusedGlobalSymbols
  abs = Math.abs;

  private async retrieveOrderData(orderId: string) {
    const goAway = () => this.router.navigate(["/"]);

    const order = await getOrderFragment(orderId, this.dataService);

    if (!order || !orderHasValidRefunds(order) || !order.customer) {
      return goAway();
    }

    this.order = order;

    const refundsInfo = await getRefundsInfo(order);

    if (_.isEmpty(refundsInfo)) {
      return goAway();
    }

    this.refundsInfo = refundsInfo;

    const customer = await getCustomerFragment(order.customer.id, this.dataService);

    this.customerNumber = (customer.customFields as any).customerNumber;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
  ) {
    const orderId = route.snapshot.params["orderId"];

    this.retrieveOrderData(orderId).then();
  }
}
