import {registerReactCustomDetailComponent} from "@vendure/admin-ui/react";

import {ProductVariantInfo} from "./ui/components/ProductVariantInfo";

export default [
  registerReactCustomDetailComponent({
    locationId: "product-variant-detail",
    component: ProductVariantInfo,
  }),
];
