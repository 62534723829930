import {addNavMenuItem} from "@vendure/admin-ui/core";

export default [
  addNavMenuItem(
    {
      id: "banner",
      label: "Werbebanner",
      routerLink: ["/extensions/banners-app"],
      icon: "heat-map", // https://clarity.design/documentation/icons/shapes,
      requiresPermission: "ReadPromotion",
    },
    "extensions",
  ),
];
