import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoiceModule } from './extensions/invoice-extension/InvoiceModule';

import SharedProviders_0_0 from './extensions/shared-extension/providers';
import SharedProviders_1_0 from './extensions/product-extension/providers';
import SharedProviders_2_0 from './extensions/invoice-extension/providers';
import SharedProviders_3_0 from './extensions/offer-extension/providers';
import SharedProviders_4_0 from './extensions/product-review-extension/providers';
import SharedProviders_5_0 from './extensions/banner-extension/providers';


@NgModule({
    imports: [CommonModule, InvoiceModule],
    providers: [...SharedProviders_0_0, ...SharedProviders_1_0, ...SharedProviders_2_0, ...SharedProviders_3_0, ...SharedProviders_4_0, ...SharedProviders_5_0],
})
export class SharedExtensionsModule {}
