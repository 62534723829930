import {addActionBarItem, addNavMenuItem} from "@vendure/admin-ui/core";

export default [
  addNavMenuItem(
    {
      id: "offer",
      label: "Angebote",
      routerLink: ["/extensions/offers-app"],
      icon: "fish", // https://clarity.design/documentation/icons/shapes,
      requiresPermission: "ReadCatalog",
    },
    "extensions",
  ),
  addActionBarItem(
    {
      id: "offer",
      label: "Angebote",
      locationId: "product-list",
      routerLink: ["/extensions/offers-app"],
      buttonColor: "primary", // 'success' | 'warning'
      buttonStyle: "outline", // 'solid' | 'link'
      icon: "fish",
      requiresPermission: "ReadCatalog",
    },
  ),
];
