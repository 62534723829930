import {QueryResult} from "@vendure/admin-ui/core";
import {lastValueFrom, Observable, take} from "rxjs";

export const queryResultToPromise = <T, V extends Record<string, any>>(queryResult: QueryResult<T, V>): Promise<T> => {
  return observableToPromise(queryResult.single$);
};

export const observableToPromise = <T>(observable: Observable<T>): Promise<T> => {
  const source$ = observable.pipe(take(1));

  return lastValueFrom(source$);
};

export function formatCurrency(value: number, currencyCode: string, locale?: string) {
  const majorUnits = value / 100;

  try {
    // Note: if no `locale` is provided, the browser's default locale will be used.
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currencyCode,
    }).format(majorUnits);
  } catch (e: any) {
    // A fallback in case the NumberFormat fails for any reason
    return majorUnits.toFixed(2);
  }
}
