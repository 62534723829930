<ng-container *ngIf="order">
  <div class="invoice-page" *ngFor="let refundInfo of refundsInfo; index as index;">
    <div class="invoice__head">
      <img class="invoice__logo" src="/assets/custom/icons/topdrop-logo.png" />
      <p class="invoice__logo-subtext">Feinste Weine - Kleinste Preise</p>
    </div>

    <div class="invoice__addresses">
      <div>
        <strong>Rechnungsadresse</strong>
        <p>{{ order.billingAddress.fullName }}</p>
        <p>{{ order.billingAddress.streetLine1 }}</p>
        <p>{{ order.billingAddress.streetLine2 }}</p>
        <p>{{ order.billingAddress.postalCode }} {{ order.shippingAddress.city }}</p>
        <p>{{ order.billingAddress.country }}</p>
      </div>

      <div>
        <strong>Lieferadresse</strong>
        <p>{{ order.shippingAddress.fullName }}</p>
        <p>{{ order.shippingAddress.streetLine1 }}</p>
        <p>{{ order.shippingAddress.streetLine2 }}</p>
        <p>{{ order.shippingAddress.postalCode }} {{ order.shippingAddress.city }}</p>
        <p>{{ order.shippingAddress.country }}</p>
      </div>

      <div class="invoice__address-right">
        <p>Topdropmarket GmbH</p>
        <p>Hirschgraben 24</p>
        <p>22089 Hamburg</p>
        <p class="invoice__address-phone">Tel. 0173 313 29 48</p>
        <p>info&#64;topdropmarket.de</p>
        <p>www.topdropmarket.de</p>
      </div>
    </div>

    <h1 class="invoice__headline">Gutschriftsbeleg {{ index + 1 }}</h1>

    <div class="invoice__order-data">
      <div>
        <div>
          <strong>Kundennummer: </strong>
          <span>{{ customerNumber }}</span>
        </div>

        <div>
          <strong>Gutschriftsnummer: </strong>
          <span>{{ refundInfo.refundNumber }}</span>
        </div>
      </div>

      <div>
        <strong>Datum: </strong>
        <span>{{ refundInfo.createdAt | date: 'dd.MM.yyyy' }}</span>
      </div>
    </div>

    <div class="invoice__table">
      <div class="invoice__table-row">
        <strong class="invoice__table-cell--desc">Artikelbezeichnung</strong>
        <strong class="invoice__table-cell">Artikelnummer</strong>
        <strong class="invoice__table-cell">Preis</strong>
        <strong class="invoice__table-cell">Menge</strong>
        <strong class="invoice__table-cell">Summe</strong>
      </div>

      <div class="invoice__table-row" *ngFor="let entry of refundInfo.entries">
        <span class="invoice__table-cell--desc">{{ entry.variantName }}</span>
        <span class="invoice__table-cell">{{ entry.variantSku }}</span>
        <span class="invoice__table-cell">{{ entry.unitPriceWithTax / 100 | currency:"EUR" }}</span>
        <span class="invoice__table-cell">{{ entry.refundQuantity }}</span>
        <span class="invoice__table-cell">- {{ entry.refundPriceWithTax / 100 | currency:"EUR" }}</span>
      </div>

      <div class="invoice__table-row invoice__discount-row" *ngIf="refundInfo.coupon">
        <span class="invoice__table-cell--desc">Gutscheine eingelöst: {{ refundInfo.coupon.code }}</span>
        <span class="invoice__table-cell"> </span>
        <span class="invoice__table-cell"> </span>
        <span class="invoice__table-cell"> </span>
        <span class="invoice__table-cell">+ {{ refundInfo.coupon.amountWithTax / -100 | currency:"EUR" }}</span>
      </div>

      <div class="invoice__table-row invoice__discount-row" *ngIf="refundInfo.adjustment">
        <span class="invoice__table-cell--desc">{{ "Anpassung" }}</span>
        <span class="invoice__table-cell"> </span>
        <span class="invoice__table-cell"> </span>
        <span class="invoice__table-cell"> </span>
        <span class="invoice__table-cell">
          {{ refundInfo.adjustment < 0 ? "-" : "+" }}
          {{ abs(refundInfo.adjustment) / 100 | currency:"EUR" }}
        </span>
      </div>
    </div>

    <div class="invoice__summary-row" *ngIf="refundInfo.tax">
      <strong>Enthaltene MwSt. 19%: </strong>
      <span class="invoice__summary-price">
        {{ refundInfo.totalTaxFromLines / 100 | currency:"EUR" }}
      </span>
    </div>

    <div class="invoice__summary-row">
      <strong>Gutschrift: </strong>
      <strong class="invoice__summary-price">
        {{ refundInfo.totalRefundPriceWithTax / 100 | currency:"EUR" }}
      </strong>
    </div>

    <br />

    <p>
      Wir liefern und berechnen die angeführten Waren zu unseren Allgemeinen Geschäftsbedingungen. Bezüglich der
      Entgeltminderung verweisen wir auf unsere aktuellen Zahlungs- und Konditionsvereinbarungen. Das Rechnungsdatum
      entspricht dem Lieferdatum.
    </p>

    <br />

    <p>Die gelieferte Ware bleibt bis zur vollständigen Bezahlung unser Eigentum.</p>

    <div class="invoice__footer">
      <div>
        <p>topdropmarket GmbH</p>
        <p>Hirschgraben 24</p>
        <p>22089 Hamburg</p>
      </div>

      <div>
        <p>IBAN: DE70 2005 0550 1261 1870 49</p>
        <p>BIC: HASP DEHH XXX</p>
        <p>Hamburger Sparkasse</p>
      </div>

      <div></div>

      <div>
        <p>USt-IdNr.: DE290397170</p>
        <p>AG Hamburg HRB 127969</p>
      </div>
    </div>

    <div class="page-break"></div>
  </div>
</ng-container>
