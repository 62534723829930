import {NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {AuthGuard, SharedModule} from "@vendure/admin-ui/core";

import {InvoiceComponent} from "./InvoiceComponent";
import {DeliveryNoteComponent} from "./DeliveryNoteComponent";
import {RefundInvoiceComponent} from "./RefundInvoiceComponent";

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild([
      {
        path: "orders/:orderId/invoice/html",
        component: InvoiceComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "orders/:orderId/delivery-note/html",
        component: DeliveryNoteComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "orders/:orderId/refund-invoice/html",
        component: RefundInvoiceComponent,
        canActivate: [AuthGuard],
      },
    ]),
  ],

  declarations: [InvoiceComponent, DeliveryNoteComponent, RefundInvoiceComponent],
})
export class InvoiceModule {
}
